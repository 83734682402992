<template>
  <Form class="terms-form" v-slot="{ invalid, changed }">
    <div class="grid-2 mb-40">
      <FormItem rules="required" v-slot="{ isError }">
        <Input
          v-model="form.name"
          label="Name"
          placeholder="Name"
          :is-error="isError"
          :is-disabled="isDisabled"
        />
      </FormItem>
      <FormItem>
        <Input
          v-model="form.description"
          label="Description"
          placeholder="Description"
          :is-disabled="isDisabled"
        />
      </FormItem>
      <FormItem class="grid-2__full-item" rules="required" v-slot="{ isError }">
        <RichTextEditor
          v-model="form.text"
          label="Text"
          :placeholder="!isDisabled ? 'Text' : ''"
          :is-error="isError"
          :is-disabled="isDisabled"
        />
      </FormItem>
    </div>
    <div class="terms-form__actions mt-auto">
      <Button
        v-if="!isDisabled"
        :is-disabled="invalid || !changed"
        :is-loading="isSubmitting"
        :is-block="['xs', 'sm'].includes($mq)"
        @click="$emit('submit', form)"
      >
        Save
      </Button>
      <Guard permission="terms.delete" v-slot="{ isAvailable }">
        <Button
          v-if="isAvailable && isEditing"
          is-outlined
          variant="danger"
          :is-disabled="isSubmitting"
          :is-loading="isDeleting"
          :is-block="['xs', 'sm'].includes($mq)"
          @click="handleDelete"
        >
          Delete
        </Button>
      </Guard>
    </div>
  </Form>
</template>

<script>
import RichTextEditor from "@/components/common/richTextEditor/RichTextEditor";
import dialog from "@/plugins/dialog";
import { mapActions } from "vuex";
import Guard from "@/components/common/Guard";
export default {
  name: "TermsForm",
  components: { Guard, RichTextEditor },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        ...this.value,
      },
      isDeleting: false,
    };
  },
  computed: {
    isEditing() {
      return this.$route.name === "EditTerms";
    },
  },
  methods: {
    ...mapActions({
      deleteTerms: "terms/deleteTerms",
    }),
    async handleDelete() {
      const confirmed = await dialog.confirm({
        title: "Are you sure?",
        message: "All terms data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });

      if (confirmed) {
        try {
          this.isDeleting = true;
          await this.deleteTerms(this.$route.params.id);
          await this.$router.push({ name: "Terms" });
        } finally {
          this.isDeleting = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-form {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
</style>
