<template>
  <div class="edit-terms-page">
    <BackTitle
      :class="['xs', 'sm'].includes($mq) ? 'mb-40' : 'mb-64'"
      confirm-click
      @click="goBack"
    >
      {{ terms ? terms.name : "" }}
    </BackTitle>
    <Loader v-if="isLoading" class="mx-auto" size="m" color="primary" />
    <Guard v-else-if="terms" permission="terms.edit" v-slot="{ isAvailable }">
      <TermsForm
        :value="terms"
        :is-submitting="isSubmitting"
        :is-disabled="!isAvailable"
        @submit="handleSubmit"
      />
    </Guard>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import TermsForm from "@/components/terms/TermsForm";
import { mapActions, mapState } from "vuex";
import Guard from "@/components/common/Guard";
export default {
  name: "EditTermsPage",
  components: { Guard, TermsForm, BackTitle },
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      terms: (state) => state.terms.editedItem,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchTermsById(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchTermsById: "terms/fetchTermsById",
      updateTerms: "terms/updateTerms",
    }),
    goBack() {
      this.$router.push({
        name: "Terms",
      });
    },
    async handleSubmit({ id, ...data }) {
      try {
        this.isSubmitting = true;
        await this.updateTerms({ id, data });
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-terms-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
